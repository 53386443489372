import {
  UPDATE_EMAIL,
  UPDATE_PASSWORD,
  // ON_LOGGEDIN,
} from "../actions/LoginAction";

const data = JSON.parse(localStorage.getItem("data"));

const initialState = {
  token: data?.token,
  role: data?.role,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_EMAIL: {
      return {
        ...state,
        email: action.payload,
      };
    }

    case UPDATE_PASSWORD: {
      return {
        ...state,
        password: action.payload,
      };
    }

    case "ON_LOGGEDIN": {
      return {
        ...state,
        token: action?.token,
        role: action?.data?.role,
      };
    }

    case "LOGOUT": {
      return {
        ...state,
        token: null,
        role: null,
      };
    }

    default:
      return state;
  }
};

export default loginReducer;
