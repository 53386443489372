export const UPDATE_EMAIL = "loginReducer/UPDATE_EMAIL";
export const UPDATE_PASSWORD = "loginReducer/UPDATE_PASSWORD";
// export const ON_LOGGEDIN = "loginReducer/ON_LOGGEDIN";

export const updateEmail = (val) => (disptch) => {
  disptch({
    type: UPDATE_EMAIL,
    payload: val,
  });
};

export const updatePassword = (val) => (disptch) => {
  disptch({
    type: UPDATE_PASSWORD,
    payload: val,
  });
};

export const onLoggedin = (result) => {
  return (dispatch) => {
    var obj = {
      token: result?.data?.access_token,
      role: result?.data?.role,
    };
    localStorage.setItem("data", JSON.stringify(obj));
    dispatch({
      type: "ON_LOGGEDIN",
      token: result?.data?.access_token,
      data: result?.data,
    });
  };
};

export const onRefreshToken = (result) => {
  return (dispatch) => {
    var obj = {
      token: result?.data?.access_token,
      role: result?.data?.role,
    };
    localStorage.setItem("data", JSON.stringify(obj));
    dispatch({
      type: "ON_LOGGEDIN",
      token: result?.data?.access_token,
      data: result?.data,
    });
  };
};

export const onGetUser = (result) => {
  return (dispatch) => {
    dispatch({
      type: "ON_GETUSER",
      data: result?.data,
    });
  };
}

export const logOut = () => {
  return (dispatch) => {
    localStorage.removeItem("data");

    dispatch({
      type: "LOGOUT",
      // token: result.data.token,
      // data: result.data.responseData,
    });
  };
};
